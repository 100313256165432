import isEqual from "lodash/isEqual";
import {
    buildApiV3Url,
    fixSearchStringForPostgres,
    transformExemptionData,
} from "../../shared/Utils";
import axios from "../../axios";
import { setCertificateIds } from "../bulkUpdateSlice";
import {
    decrementCertificateCountAPICompleted,
    incrementCertificateCountAPICompleted,
    initialState,
    reloadCertificateStatuses,
    setFormState,
    setIsLoadingCertificateCount,
    setLoading,
    setNoDataType,
    setNoSearch,
    setPage,
    setPageToNull,
} from ".";
import { CertificateStatuses, completeRequestStatuses } from "../../shared/constants";

// Helper functions

const isFiltersEmpty = filtersState => {
    const filtersWithoutSavedSearch = { ...filtersState };
    delete filtersWithoutSavedSearch.savedSearch;
    delete filtersWithoutSavedSearch.isCustomerDetails;
    delete filtersWithoutSavedSearch.isCertificateDetails;
    const initialStateWithoutSavedSearch = { ...initialState.certificateFiltersState };
    delete initialStateWithoutSavedSearch.savedSearch;
    delete initialStateWithoutSavedSearch.isCustomerDetails;
    delete initialStateWithoutSavedSearch.isCertificateDetails;

    return isEqual(filtersWithoutSavedSearch, initialStateWithoutSavedSearch);
};

const getExemptionStatuses = (certificateFiltersState, statuses) => {
    const exemptionStatuses = [];
    const hasValidStatus = completeRequestStatuses.some(status =>
        certificateFiltersState?.certificateStatus?.includes(status)
    );
    if (hasValidStatus) {
        const exemptionStatus = statuses?.filter(
            status =>
                status?.name === CertificateStatuses.Complete ||
                status?.name === CertificateStatuses.PENDING_FUTURE
        );
        exemptionStatus?.forEach(ele => exemptionStatuses.push(ele?.id));
    }
    if (certificateFiltersState?.certificateStatus?.includes(CertificateStatuses.PendingFuture)) {
        exemptionStatuses.push(
            statuses.filter(status => status?.name === CertificateStatuses.PENDING_FUTURE)[0]?.id
        );
    }
    return exemptionStatuses;
};

const getSearchVal = (formState, certificateFiltersState) => {
    if (formState.nameOrCodeWhileLoading !== "") return formState.nameOrCodeWhileLoading;
    if (certificateFiltersState?.nameOrCode !== "") return certificateFiltersState.nameOrCode;
    return formState.nameOrCode;
};

const createRequestBody = (
    searchVal,
    certificateFiltersState,
    pageSize,
    pageNumber,
    sortColumn,
    exemptionStatuses
) => ({
    pageSize,
    pageNumber,
    orderBy: sortColumn[0],
    orderByDirection: sortColumn[1] ? "DESC" : "ASC",
    nameOrCode: fixSearchStringForPostgres(searchVal),
    purchaseOrderNumber: certificateFiltersState.invoiceNumber,
    isPrimary: certificateFiltersState.isCertificateActive,
    isBillTo: certificateFiltersState.isBillToRelation,
    isShipTo: certificateFiltersState.isShipToRelation,
    isDuplicate: certificateFiltersState.isDuplicateRelation,
    isSingle: certificateFiltersState.isPurchaseOrder,
    certificateImageExists: certificateFiltersState.isCertificateImage,
    customerCreatedFrom: certificateFiltersState.customerCreatedDateAfter || null,
    customerCreatedTo: certificateFiltersState.customerCreatedDateBefore || null,
    customerModifiedFrom: certificateFiltersState.customerModifiedDateAfter || null,
    customerModifiedTo: certificateFiltersState.customerModifiedDateBefore || null,
    certificateCreatedFrom: certificateFiltersState.certificateCreatedDateAfter || null,
    certificateCreatedTo: certificateFiltersState.certificateCreatedDateBefore || null,
    certificateModifiedFrom: certificateFiltersState.certificateModifiedDateAfter || null,
    certificateModifiedTo: certificateFiltersState.certificateModifiedDateBefore || null,
    certificateEffectiveFrom: certificateFiltersState.certificateEffectiveDateAfter || null,
    certificateEffectiveTo: certificateFiltersState.certificateEffectiveDateBefore || null,
    certificateExpirationFrom: certificateFiltersState.certificateExpirationDateAfter || null,
    certificateExpirationTo: certificateFiltersState.certificateExpirationDateBefore || null,
    status: certificateFiltersState.certificateStatus,
    exemptionStatus: exemptionStatuses,
    exposureZones: certificateFiltersState.certificateRegions,
    exemptionReasons: certificateFiltersState.exemptReasons,
    customerRegions: certificateFiltersState.customerRegions,
    certificateIds: certificateFiltersState.certificateIds?.length
        ? certificateFiltersState.certificateIds
              .split("\n")
              .map(value => fixSearchStringForPostgres(value.trim()))
              .map(Number)
        : [],
    customerCodes: certificateFiltersState.customerCodes?.length
        ? certificateFiltersState.customerCodes
              .split("\n")
              .map(value => fixSearchStringForPostgres(value.trim()))
        : [],
    activeCertificate: certificateFiltersState.isCertActive,
    certificateHasCustomers: certificateFiltersState.certificateHasCustomers,
    certificateCustomFields: certificateFiltersState.certificateCustomFields,
    certificatesLabels: certificateFiltersState.certificatesLabels,
    customersLabels: certificateFiltersState.customersLabels,
    reviewedBy: certificateFiltersState.reviewedBy,
});

// Loading State Handling
const handleLoadingState = (dispatch, formState, searchVal) => {
    dispatch(setLoading(true));
    if (formState.isloading) {
        dispatch(
            setFormState({
                ...formState,
                nameOrCodeWhileLoading: searchVal,
            })
        );
        return true; // Skip API call
    }
    return false;
};

// API Call & Response Handling
const fetchCertificatesData = async (requestBody, dispatch, onLanding, pageSize) => {
    try {
        const apiResponse = await axios.post(buildApiV3Url(`certificates/search`), requestBody, {
            withCredentials: true,
        });

        if (!apiResponse || !apiResponse.data) {
            dispatch(setNoDataType(onLanding ? "onLanding" : "onSearch"));
            dispatch(setPageToNull(pageSize));
            dispatch(setLoading(false));
            return null;
        }

        return apiResponse;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching certificates:", error);
        dispatch(setLoading(false));
        return null;
    }
};

// Pagination Handling
const handlePagination = (
    apiResponse,
    pageData,
    dispatch,
    pageSize,
    pageNumber,
    paginateDetails,
    response
) => {
    const paginateData = paginateDetails
        ? {
              pageNumber,
              startIndex: paginateDetails.startIndex,
              endIndex: paginateDetails.endIndex,
              rowsPerPage: paginateDetails.rowsPerPage,
              totalRecords: pageData.totalRecords,
              indeterminate: false,
          }
        : {
              pageNumber,
              startIndex: 0,
              endIndex: 19,
              rowsPerPage: pageSize,
              totalRecords:
                  response?.isCertificateCountAPICompleted === 0 ? pageData.totalRecords : 20,
              indeterminate: response?.isCertificateCountAPICompleted !== 0,
          };

    dispatch(
        setPage({
            data: response.data.value,
            paginate: paginateData,
        })
    );
};

// Certificate Count API Handling
const fetchCertificateCount = async (requestBody, dispatch, getState) => {
    try {
        const res = await axios.post(buildApiV3Url(`certificates/search-count`), requestBody, {
            withCredentials: true,
        });

        const { page } = getState().certificate;
        const pageData = { ...page.paginate };
        pageData.totalRecords = res?.data;
        pageData.indeterminate = false;

        dispatch(
            setPage({
                data: page.data,
                paginate: pageData,
            })
        );
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching certificate count:", error);
    }
};

const fetchCertificatesSearchAPI =
    (onLanding, paginateDetails, pageSize, pageNumber = 1) =>
    async (dispatch, getState) => {
        const {
            formState,
            certificateFiltersState,
            sortColumn,
            certificateStatuses,
            isCertificateGridEllipsesMenu,
        } = getState().certificate;
        const { rowsPerPage } = getState().session;

        pageSize = pageSize || rowsPerPage;
        const searchVal = getSearchVal(formState, certificateFiltersState);

        // Handle loading state early and return if skip
        if (handleLoadingState(dispatch, formState, searchVal)) return;

        let statuses = certificateStatuses;
        await reloadCertificateStatuses(dispatch);
        if (localStorage.getItem("certificateStatuses")) {
            statuses = JSON.parse(localStorage.getItem("certificateStatuses"));
        }

        const exemptionStatuses = getExemptionStatuses(certificateFiltersState, statuses);

        // Handle filter state and search conditions
        if (isFiltersEmpty(certificateFiltersState) && searchVal === "") {
            dispatch(setNoSearch(true));
        } else {
            dispatch(setNoSearch(false));
        }

        const requestBody = createRequestBody(
            searchVal,
            certificateFiltersState,
            pageSize,
            pageNumber,
            sortColumn,
            exemptionStatuses
        );

        const apiResponse = await fetchCertificatesData(requestBody, dispatch, onLanding, pageSize);

        if (!apiResponse) return;

        const response = transformExemptionData(apiResponse, false, false, [
            "expectedTaxCodeName",
            "taxCodeName",
        ]);
        handlePagination(
            apiResponse,
            getState().certificate.page.paginate,
            dispatch,
            pageSize,
            pageNumber,
            paginateDetails,
            response
        );

        // Set the certificate IDs
        const certificateIds = response.data.value.map(element => element.certificateId);
        dispatch(setCertificateIds(certificateIds));

        // Clear loading state and reset search value
        dispatch(setLoading(false));

        if (formState.nameOrCodeWhileLoading !== "") {
            await dispatch(
                setFormState({
                    ...formState,
                    nameOrCodeWhileLoading: "",
                })
            );
        }

        // Fetch certificate count if necessary
        if (!paginateDetails || isCertificateGridEllipsesMenu) {
            dispatch(setIsLoadingCertificateCount(true));
            dispatch(incrementCertificateCountAPICompleted());
            await fetchCertificateCount(requestBody, dispatch, getState);
            dispatch(decrementCertificateCountAPICompleted());
            dispatch(setIsLoadingCertificateCount(false));
        }
    };

export default fetchCertificatesSearchAPI;
