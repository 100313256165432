import { createSlice } from "@reduxjs/toolkit";
import { buildApiV3UrlWithQuery } from "../shared/Utils";
import axiosInstanceWithoutInterceptor from "../axiosWithoutInterceptor";
import toast from "../hooks/toast";

const [showToast] = toast();
const initialState = {
    isLoading: false,
    companies: [],
    isLoadingCompanies: false,
    selectedGroup: null,
    isLoadingGroup: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    formState: {
        nameOrCode: "",
    },
    sortColumn: ["displayName", true],
    companyGroups: [],
};

export const selectIsLoading = state => {
    return state.companyGroups.isLoading;
};
export const selectPage = state => {
    return state.companyGroups.page;
};
export const selectFormState = state => {
    return state.companyGroups.formState;
};
export const selectSortColumn = state => {
    return state.companyGroups.sortColumn;
};
export const selectCompanyGroups = state => {
    return state.companyGroups.companyGroups;
};
export const selectIsLoadingCompanies = state => {
    return state.companyGroups.isLoadingCompanies;
};
export const selectCompanies = state => {
    return state.companyGroups.companies;
};
export const selectSelectedGroup = state => {
    return state.companyGroups.selectedGroup;
};
export const selectIsLoadingGroup = state => {
    return state.companyGroups.isLoadingGroup;
};
export const companyGroupsSlice = createSlice({
    name: "companyGroups",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setCompanyGroups: (state, action) => {
            state.companyGroups = action.payload;
        },
        clearCompanyGroupsSearch: (state, action) => {
            state.formState = initialState.formState;
        },
        setIsLoadingCompanies: (state, action) => {
            state.isLoadingCompanies = action.payload;
        },
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },
        setLoadingGroup: (state, action) => {
            state.isLoadingGroup = action.payload;
        },
        setSelectedGroup: (state, action) => {
            state.selectedGroup = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
    },
});
export const {
    setIsLoading,
    setCompanies,
    setIsLoadingCompanies,
    setPage,
    setPageToNull,
    setFormState,
    setSortColumn,
    setCompanyGroups,
    clearCompanyGroupsSearch,
    setLoadingGroup,
    setSelectedGroup,
} = companyGroupsSlice.actions;

export const fetchCompanyGroups = (top, skip, paginateDetails) => async (dispatch, getState) => {
    const { isLoading, formState, sortColumn } = getState().companyGroups;
    const { rowsPerPage, activeCompany } = getState().session;
    top = top || rowsPerPage;
    if (isLoading) {
        return;
    }
    dispatch(setIsLoading(true));
    const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    if (formState?.nameOrCode) {
        filter = `displayName like '${formState?.nameOrCode.trim()}'`;
    }
    const apiURI = buildApiV3UrlWithQuery(
        `companies/${activeCompany.id}/company-divisions`,
        filter,
        "",
        top,
        skip,
        sort
    );
    const response = await axiosInstanceWithoutInterceptor.get(`${apiURI}`, {
        withCredentials: true,
    });
    if (!response?.data) {
        dispatch(setPageToNull());
    } else {
        const paginateData = paginateDetails
            ? {
                  pageNumber: paginateDetails.currentPage,
                  startIndex: paginateDetails.startIndex,
                  endIndex: paginateDetails.endIndex,
                  rowsPerPage: paginateDetails.rowsPerPage,
                  totalRecords: response.data.count,
              }
            : {
                  pageNumber: 1,
                  startIndex: 0,
                  endIndex: 19,
                  rowsPerPage: 20,
                  totalRecords: response.data.count,
              };
        dispatch(
            setPage({
                data: response.data.value,
                paginate: paginateData,
            })
        );
        dispatch(setCompanyGroups(response.data.value));
    }

    dispatch(setIsLoading(false));
};

export const createCompanyGroup = payload => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const { activeCompany } = getState().session;
    const response = await axiosInstanceWithoutInterceptor.post(
        buildApiV3UrlWithQuery(`companies/${activeCompany.id}/company-divisions`),
        payload,
        {
            withCredentials: true,
        }
    );

    if (response.status === 200) {
        dispatch(setIsLoading(false));
        showToast("success", "Company group created successfully");
        dispatch(fetchCompanyGroups());
    } else {
        showToast("error", response.message);
    }
};

export const updateCompanyGroup = (groupId, payload) => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const { activeCompany } = getState().session;
    const response = await axiosInstanceWithoutInterceptor.put(
        buildApiV3UrlWithQuery(`companies/${activeCompany.id}/company-divisions/${groupId}`),
        payload,
        {
            withCredentials: true,
        }
    );

    if (response.status === 200) {
        dispatch(setIsLoading(false));
        showToast("success", "Company group updated successfully");
        dispatch(fetchCompanyGroups());
    } else {
        showToast("error", response.message);
    }
};

export const fetchCompanies = () => async (dispatch, getState) => {
    const { activeCompany } = getState().session;
    dispatch(setIsLoadingCompanies(true));

    const response = await axiosInstanceWithoutInterceptor.get(
        buildApiV3UrlWithQuery(`companies/${activeCompany.id}/company-divisions/mappings`),
        {
            withCredentials: true,
        }
    );

    const companiesList = response.data.map(company => ({
        label: company.name,
        value: company.name,
        companyId: company.companyId,
        isDivisionMapped: company.isDivisionMapped,
    }));

    dispatch(setCompanies(companiesList));
    dispatch(setIsLoadingCompanies(false));
};

export const deleteCompanyGroup = groupId => async (dispatch, getState) => {
    const { activeCompany } = getState().session;

    const response = await axiosInstanceWithoutInterceptor.delete(
        buildApiV3UrlWithQuery(`companies/${activeCompany.id}/company-divisions/${groupId}`),
        {
            withCredentials: true,
        }
    );

    if (response.status === 204) {
        dispatch(fetchCompanyGroups());
        showToast("success", "Company group deleted successfully");
    } else {
        showToast("error", response.message);
    }
    return response;
};

export const fetchCompanyGroupById = groupId => async (dispatch, getState) => {
    dispatch(setLoadingGroup(true));
    const { activeCompany } = getState().session;

    const response = await axiosInstanceWithoutInterceptor.get(
        buildApiV3UrlWithQuery(`companies/${activeCompany.id}/company-divisions/${groupId}`),
        {
            withCredentials: true,
        }
    );
    if (response?.data) {
        const groupData = {
            name: response.data.name,
            companies: response.data.companies
                ? response.data.companies.map(company => ({
                      label: company.name,
                      value: company.name,
                      companyId: company.companyId,
                  }))
                : [],
            groupId: response.data.groupId,
        };
        dispatch(setSelectedGroup(groupData));
    }
};

export default companyGroupsSlice.reducer;
