export const subscription = {
    ECMPremium: "ECMPremium",
    ECMStandard: "ECMStandard",
    ECMPro: "ECMPro",
    ECMEssentials: "ECMEssentials",
    AvaTaxExemptions: "AvaTaxExemptions",
    VEPRO: "VEMPro",
    VEPREMIUM: "VEMPremium",
    VEENT: "VEMEnt",
    AVATAXAP: "AvaTaxAP",
    ECMProComms: "ECMProComms",
    ECMPremiumComms: "ECMPremiumComms",
    AVAGLOBAL: "AvaGlobal",
    AVATAXST: "AvaTaxST",
    AVATAXPRO: "AvaTaxPro",
};

export const tier = {
    ENT: "ENT",
};

export const advanceSubscription = [
    subscription.ECMPremium,
    subscription.ECMStandard, // Standard is previous name of pro
    subscription.ECMPro,
];
export const userRoleAdminSubscription = [
    subscription.ECMPremium,
    subscription.ECMEssentials, // Standard is previous name of pro
    subscription.ECMPro,
    subscription.ECMStandard,
    subscription.VEPREMIUM,
    subscription.VEPRO,
    subscription.VEENT,
    subscription.ECMProComms,
    subscription.ECMPremiumComms,
];
export const vendorSubscription = [subscription.VEPRO, subscription.VEPREMIUM, subscription.VEENT];

export const vendorAvaTaxAPSubscription = [subscription.AVATAXAP];

export const vendorPremiumOnlySubscription = [subscription.VEPREMIUM, subscription.VEENT];

export const ecmSubscription = [...advanceSubscription, subscription.ECMEssentials];

export const lowerSubscription = [subscription.AvaTaxExemptions, subscription.ECMEssentials];

export const afcSubscription = [subscription.ECMProComms, subscription.ECMPremiumComms];

export const customerSubscription = [
    subscription.ECMPremium,
    subscription.ECMPro,
    subscription.ECMEssentials,
    subscription.AvaTaxExemptions,
];

export const avaTaxSubscription = [
    subscription.AVAGLOBAL,
    subscription.AVATAXST,
    subscription.AVATAXPRO,
];

export const ecmRole = {
    CEMAdmin: "cem_admin",
    CEMManager: "cem_manager",
    CEMCertificateReviewer: "cem_certificatereviewer",
    CEMViewer: "cem_viewer",
    CEMNoAccess: "cem_noaccess",
    RETManager: "ret_manager",
    RETAssociate: "ret_associate",
    RETViewer: "ret_viewer",
    MSUser: "managed_service_user",
    CEMPreValidator: "cem_pre_validator",
};

export const certificateExemptionManagementTag = "cem_";

export const retailTag = "ret_";

export const ecmCEMRole = Object.values(ecmRole).filter(x =>
    x.startsWith(certificateExemptionManagementTag)
);

export const ecmRETRole = Object.values(ecmRole).filter(x => x.startsWith(retailTag));

export const avataxRole = {
    ECMRoleUser: "ECMRoleUser",
    ECMAccountUser: "ECMAccountUser",
    ECMCompanyUser: "ECMCompanyUser",
};

export default subscription;
